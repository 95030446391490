const merge = require('lodash/merge');

const Cookies = require('js-cookie');
const { detect } = require('detect-browser');

const browser = detect();

const [w, d] = [window, document];

const homeImages = [
  'assets/images/home/v2/block_bg_1@desktop.jpg',
  'assets/images/home/v2/block_bg_1@mobile.jpg',
  'assets/images/home/v2/block_bg_2@desktop.jpg',
  'assets/images/home/v2/block_bg_2@mobile.jpg',
  'assets/images/home/v2/block_bg_3-4@desktop.jpg',
  'assets/images/home/v2/block_bg_3-4@mobile.jpg',
  'assets/images/home/v2/block_bg_4@desktop.jpg',
  'assets/images/home/v2/block_bg_4@mobile.jpg',
  'assets/images/home/v2/block_bg_5@desktop.jpg',
  'assets/images/home/v2/block_bg_5@mobile.jpg',
  'assets/images/home/v2/block_bg_6@desktop.jpg',
  'assets/images/home/v2/block_bg_6@mobile.jpg',
  'assets/images/home/v2/block_bg_7@desktop.jpg',
  'assets/images/home/v2/block_bg_7@mobile.jpg',
  'assets/images/home/v2/block_bg_8@desktop.jpg',
  'assets/images/home/v2/block_bg_8@mobile.jpg',
  'assets/images/home/v2/block_bg_9@desktop.jpg',
  'assets/images/home/v2/block_bg_9@mobile.jpg',
  'assets/images/home/v2/block_bg_10@desktop.jpg',
  'assets/images/home/v2/block_bg_10@mobile.jpg',
  'assets/images/home/v2/block_bg_11@desktop.jpg',
  'assets/images/home/v2/block_bg_11@mobile.jpg',
  'assets/images/home/v2/block_bg_12@desktop.jpg',
  'assets/images/home/v2/block_bg_12@mobile.jpg',
  'assets/images/home/v2/block_bg_13@desktop.jpg',
  'assets/images/home/v2/block_bg_13@mobile.jpg',
  'assets/images/home/v2/block_bg_14@desktop.jpg',
  'assets/images/home/v2/block_bg_14@mobile.jpg',
  'assets/images/home/v2/block_bg_15@desktop.jpg',
  'assets/images/home/v2/block_bg_15@mobile.jpg',
  'assets/images/home/v2/block_bg_16@desktop.jpg',
  'assets/images/home/v2/block_bg_16@mobile.jpg',
  'assets/images/home/v2/block_bg_17@desktop.jpg',
  'assets/images/home/v2/block_bg_17@mobile.jpg',
  'assets/images/home/v2/block_bg_18@desktop.jpg',
  'assets/images/home/v2/block_bg_18@mobile.jpg',
  'assets/images/home/v2/block_bg_19@desktop.jpg',
  'assets/images/home/v2/block_bg_19@mobile.jpg',
  'assets/images/home/v2/block_bg_19@mobile.webp',
  'assets/images/home/v2/block_bg_20@desktop.jpg',
  'assets/images/home/v2/block_bg_20@mobile.jpg',
  'assets/images/home/v2/block_bg_21@desktop.jpg',
  'assets/images/home/v2/block_bg_21@mobile.jpg',
]

document.addEventListener('DOMContentLoaded', e => {
  const root = document.documentElement;
  root.classList.add(browser.name);

  const globalNav = new Menu(d.getElementsByClassName('c-nav')[0]);
  const modal = new Modal(d.getElementById('modal'));

  const homeContent = document.querySelector('.p-home__content')

  if (homeContent) {
    (async () => {
      await loading(homeImages)

      homeInit()
    })()
  }
});

async function loading(srcs) {
  const element = document.querySelector('.c-loading')
  const progress = element.querySelector('.c-loading__progress')
  const status = element.querySelector('.c-loading__status')
  const percent = ''
  const results = []

  let count = 0
  let ratio = 0

  async function loadImage(src) {
    const img = new Image()
    img.src = src
    await img.decode()

    ratio = ++count / srcs.length
    element.style.setProperty('--loading-progress', ratio)
    progress.textContent = `${Math.round(ratio * 100)}%`

    return img
  }

  await Promise.all(homeImages.map(src => loadImage(src)))

  status.textContent = 'Complete!'
  element.classList.add('is-done')
}

function homeInit() {
  $(function() {
    let pageNumber = 0
    let pageMax = 0

    const homeContainer = document.querySelector('.p-home__container');

    const pagesElement   = homeContainer.querySelector('.p-home__container__pages')
    const pagesText      = homeContainer.querySelector('.p-home__container__pages__text')
    const pagesCurrent   = homeContainer.querySelector('.p-home__container__pages__current')
    const pagesSeparator = homeContainer.querySelector('.p-home__container__pages__separator')
    const pagesMax       = homeContainer.querySelector('.p-home__container__pages__max')
    const pagesMessage   = homeContainer.querySelector('.p-home__container__pages__message')
    const pagesProgress  = homeContainer.querySelector('.p-home__container__pages__progress .progress')

    const pagesButtons = ['start', 'prev', 'next', 'end']
    const pagesButtonElements = pagesButtons.map((name) => pagesElement.querySelector(`.p-home__container__pages__nav__button--${name}`))

    const sections = document.querySelectorAll('[data-section-name]')

    const getPageNumber = $element => {
      const index = $element.index('[data-section-name]')

      return index
    }

    const getSection = (index = 0) => {
      if (sections.length > 0) {
        return [...sections].at(index)
      }
    }

    const updateMax = () => {
      const length = document.querySelectorAll('[data-section-name]')?.length;

      pagesMax.textContent = length
      pageMax = length
    }

    const updateCurrent = (index) => {
      const number = index + 1
      const ratio = (number - 1) / (pageMax - 1)

      pagesCurrent.textContent = number
      homeContainer.dataset.blockPage = number

      const dasharray = pagesProgress.getAttribute('stroke-dasharray') ?? 0

      pagesProgress.setAttribute('stroke-dashoffset', dasharray * (1 - (number - 1) / (pageMax - 1)));
      pagesElement.style.setProperty('--progress-ratio', ratio);

      let pos = ''

      pagesButtonElements.forEach((button) => button.removeAttribute('disabled'));

      if (index === 0) {
        pos = 'start'
        pagesButtonElements.slice(0, 2).forEach((button) => button.setAttribute('disabled', ''));
      } else if (index === sections.length - 1) {
        pos = 'end'
        pagesButtonElements.slice(2, 4).forEach((button) => button.setAttribute('disabled', ''));
      }

      homeContainer.dataset.blockPosition = pos
    }

    for (const buttonName of pagesButtons) {
      const selector = `.p-home__container__pages__nav__button--${buttonName}`
      const button = homeContainer.querySelector(selector)

      if (button) {
        button.addEventListener('click', (event) => {
          event.preventDefault()

          if (buttonName === 'start' || buttonName === 'end') {
            const section = getSection(buttonName === 'start' ? 0 : -1)

            if (section) {
              $.scrollify.move(`#${section.dataset.sectionName}`)
            }
          } else if (buttonName === 'prev') {
            $.scrollify.previous();
          } else if (buttonName === 'next') {
            $.scrollify.next();
          }
        })
      }
    }

    $.scrollify({
      section: '.p-home__block',
      interstitialSection : '.l-footer',
      scrollbars: false,
      scrollSpeed: 1000,
      afterRender: function() {
        pageNumber = $.scrollify.currentIndex();

        updateMax()
        updateCurrent(pageNumber)
      },
      before: function() {
        const next = $.scrollify.currentIndex();

        homeContainer.dataset.blockDirection = pageNumber < next ? 'forward' : 'backward'
        pageNumber = next;

        updateCurrent(pageNumber)
      },
      after: function() {
        homeContainer.dataset.blockPageEnd = homeContainer.dataset.blockPage
      }
    });

    window.addEventListener('hashchange', (event) => {
      const { newURL } = event;
      const hash = (new URL(newURL)).hash
      const id = hash.replace('#', '')
      const block = document.querySelector(`[data-section-name='${id}']`)

      if (block) {
        $.scrollify.move(hash)
      }
    })
  });
}

class Menu {
  constructor(element) {
    this.element = element;
    this.events = {};
    this.state = {
      isOpen: false,
    };

    const init = () => {
      this.button = this.element.getElementsByClassName('js-menu__button')[0];
      this.buttonIcon = this.button.getElementsByClassName('js-menu__button-icon')[0];
      this.buttonText = this.button.getElementsByClassName('js-menu__button-text')[0];
      this.body = this.element.getElementsByClassName('js-menu__body')[0];
      this.focusableElements = Array.from(this.body.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'));

      const handleClick = e => {
        e.preventDefault();
        this.toggle();
      };

      this.button.addEventListener('click', handleClick);
      this.events.clickButton = handleClick;

      this.close();
    };

    element && init();
  }

  open() {
    this.button.classList.add('is-open');
    this.buttonIcon.classList.add('is-open');
    this.body.classList.add('is-open');
    this.focusableElements.forEach(focusableElement => focusableElement.tabIndex = 0);
    this.state.isOpen = true;

    return this;
  }

  close() {
    this.button.classList.remove('is-open');
    this.buttonIcon.classList.remove('is-open');
    this.body.classList.remove('is-open');
    this.focusableElements.forEach(focusableElement => focusableElement.tabIndex = -1);
    this.state.isOpen = false;

    return this;
  }

  toggle() {
    this.state.isOpen? this.close(): this.open();

    return this;
  }
}

class Modal {
  constructor(element, options = {}) {
    const defaults = {
      classNames: {
        isOpen: 'is-open',
      }
    };

    this.element = element;
    this.events = {};
    this.state = {
      isOpen: false,
    };
    this.focusedElementBeforeModal = null;

    this._defaults = Object.assign({}, defaults);
    this.settings = merge({}, defaults, options);

    this.element && this.init();
  }

  init() {
    const cookieName = 'koya_disabledModal';
    const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

    this.main = document.getElementById('modal-main');
    this.buttonClose = document.getElementById('modal-close');
    this.overlay = document.getElementById('modal-overlay');

    const focusableElements = this.element.querySelectorAll(focusableElementsString);
    this.focusableElements = focusableElements && Array.from(focusableElements);
    this.focusableElements.forEach(element => element.setAttribute('tabindex', -1));

    this.firstTabStop = this.focusableElements[0],
    this.lastTabStop = this.focusableElements[this.focusableElements.length - 1];

    document.body.insertBefore(this.element, document.body.firstChild);

    this.events = {
      handleKeydown: e => {
        if (e.key === 'Escape' || e.key === 'Esc') {
          e.preventDefault();
          this.close();
        } else if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === this.firstTabStop) {
              e.preventDefault();
              this.lastTabStop.focus();
            }
          } else {
            if (document.activeElement === this.lastTabStop) {
              e.preventDefault();
              this.firstTabStop.focus();
            }
          }
        }
      },
      handleClick: e => {
        if (e.target.closest('#modal-main')) return;

        e.preventDefault();
        this.close();
      },
      closeClick: e => {
        e.preventDefault();
        this.close();
      },
      overlayClick: e => {
        e.preventDefault();
        this.close();
      }
    };

    const getCookie = name => {
      const cookie = Cookies.get(name);

      return cookie;
    };

    const setCookie = name => {
      const expireDate = new Date();
      expireDate.setHours(0, 0, 0, 0);
      expireDate.setDate(expireDate.getDate() + 1);

      Cookies.set(name, 'true', {
        expires: expireDate,
        sameSite: 'Strict'
      });
    };

    if (getCookie(cookieName) === 'true') {
      this.close();
    } else {
      this.open();
      setCookie(cookieName);
    }
  }

  #addEvent() {
    document.addEventListener('keydown', this.events.handleKeydown);
    document.addEventListener('click', this.events.handleClick);
    this.buttonClose && this.buttonClose.addEventListener('click', this.events.closeClick);
    this.overlay && this.overlay.addEventListener('click', this.events.overlayClick);
  };

  #removeEvent() {
    document.removeEventListener('keydown', this.events.handleKeydown);
    document.removeEventListener('click', this.events.handleClick);
    this.buttonClose && this.buttonClose.removeEventListener('click', this.events.closeClick);
    this.overlay && this.overlay.removeEventListener('click', this.events.overlayClick);
  }

  open() {
    const { classNames } = this.settings;

    this.focusedElementBeforeModal = document.activeElement;
    this.element.classList.add(classNames.isOpen);
    this.element.setAttribute('role', 'dialog');
    this.element.setAttribute('aria-modal', 'true');
    this.element.removeAttribute('aria-hidden');
    this.focusableElements.forEach(element => element.removeAttribute('tabindex'));
    this.#addEvent();

    document.scrollingElement.style.overflow = 'hidden';
    document.activeElement.blur();
    this.firstTabStop.focus();
    this.state.isOpen = true;
  }

  close() {
    const { classNames } = this.settings;

    this.element.classList.remove(classNames.isOpen);
    this.element.removeAttribute('role');
    this.element.removeAttribute('aria-modal');
    this.element.setAttribute('aria-hidden', 'true');
    this.focusableElements.forEach(element => element.setAttribute('tabindex', -1));

    if (!this.state.isOpen) return;

    this.#removeEvent();

    document.scrollingElement.style.overflow = '';
    document.activeElement.blur();
    this.focusedElementBeforeModal.focus();
    this.focusedElementBeforeModal = null;
    this.state.isOpen = false;
  }
}
